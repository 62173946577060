
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as loginPcxREneye5L3t4h_D7p25L74nxUAU_nwxQVdj3J_452dMMeta } from "/opt/buildhome/repo/app/pages/auth/login.vue?macro=true";
import { default as indexubE7UwXvPw0pYQKpnMdYYnkQLskx7OPx7xFhrr8CPpoMeta } from "/opt/buildhome/repo/app/pages/users/index.vue?macro=true";
import { default as create_gGo4geOwhPMEaPFqSnYReA_VWWtesMrEa9hsIcfGq8Meta } from "/opt/buildhome/repo/app/pages/users/create.vue?macro=true";
import { default as index4gku0pzaFA_LwFdeWZlLCyij7h_STckhf0HTxjzvs_45QMeta } from "/opt/buildhome/repo/app/pages/reports/index.vue?macro=true";
import { default as indexqh52qzWXUxoVZNMuaZxhP4tVtxlvhS1Xy96opW4DInQMeta } from "/opt/buildhome/repo/app/pages/settings/index.vue?macro=true";
import { default as editrS40oWmrSnQDK7n3HYrnYjM1O7xvgBaRShB8OoT24fIMeta } from "/opt/buildhome/repo/app/pages/users/[id]/edit.vue?macro=true";
import { default as accountyWezdewwcJ2LHq_E6cor8hsNJkX5yGjNwZkjqMWs05kMeta } from "/opt/buildhome/repo/app/pages/settings/account.vue?macro=true";
import { default as indexF1dlZpcsS6SYRv2q2WkLFYjJVpuvsRpvmwme_cNv4SYMeta } from "/opt/buildhome/repo/app/pages/reservations/index.vue?macro=true";
import { default as create7wqsYBmjOUnUgEaDqADIHrCmHJMnZxJsViojBwpGWcEMeta } from "/opt/buildhome/repo/app/pages/reservations/create.vue?macro=true";
import { default as editM2vgydm05y0WR1xDAq56BCZs9Xa8c6rAL3eKBf4rNuwMeta } from "/opt/buildhome/repo/app/pages/reservations/[id]/edit.vue?macro=true";
import { default as view7fhPxFCE_457XqZoXcWBQ7PZYMbvDT1iFTJb0YHQsgldkMeta } from "/opt/buildhome/repo/app/pages/reservations/[id]/view.vue?macro=true";
import { default as agencies_45reportOCDFMyfCXKGbHm1YYmhCbpHoqUo_3Z1V7Yv1J9RfA9EMeta } from "/opt/buildhome/repo/app/pages/reports/agencies-report.vue?macro=true";
import { default as week_45pre_45manifestTKNYPqnyZg_n1JNKrJj7ldytWCxFSE8sgyOTJfM8ZbQMeta } from "/opt/buildhome/repo/app/pages/reports/week-pre-manifest.vue?macro=true";
import { default as total_45reservationsi42W8IbjDFo68fUjh1nImALgrJlfvtfh9qofIXxIOVUMeta } from "/opt/buildhome/repo/app/pages/reports/total-reservations.vue?macro=true";
import { default as ratesZqWq1gbthNvdlu_cfIJ0PIKQH54q7hL6kQ2Vl76EKfwMeta } from "/opt/buildhome/repo/app/pages/settings/hotels/[id]/rates.vue?macro=true";
import { default as arrivals_45without_45flightRagAUYvPBrR93ZasjoHY9x3Ka20sVhbuIb8zzSXGds0Meta } from "/opt/buildhome/repo/app/pages/reports/arrivals-without-flight.vue?macro=true";
import { default as permissionss3u_45Phs_45FkpqK9cDzF0abMf8_qR7vt2GIZbZc3knd6sMeta } from "/opt/buildhome/repo/app/pages/settings/roles/[id]/permissions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginPcxREneye5L3t4h_D7p25L74nxUAU_nwxQVdj3J_452dMMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/login.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/buildhome/repo/app/pages/users/index.vue")
  },
  {
    name: "users-create",
    path: "/users/create",
    meta: create_gGo4geOwhPMEaPFqSnYReA_VWWtesMrEa9hsIcfGq8Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/users/create.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/opt/buildhome/repo/app/pages/reports/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/app/pages/settings/index.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editrS40oWmrSnQDK7n3HYrnYjM1O7xvgBaRShB8OoT24fIMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/users/[id]/edit.vue")
  },
  {
    name: "settings-account",
    path: "/settings/account",
    component: () => import("/opt/buildhome/repo/app/pages/settings/account.vue")
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/opt/buildhome/repo/app/pages/reservations/index.vue")
  },
  {
    name: "reservations-create",
    path: "/reservations/create",
    meta: create7wqsYBmjOUnUgEaDqADIHrCmHJMnZxJsViojBwpGWcEMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reservations/create.vue")
  },
  {
    name: "reservations-id-edit",
    path: "/reservations/:id()/edit",
    meta: editM2vgydm05y0WR1xDAq56BCZs9Xa8c6rAL3eKBf4rNuwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reservations/[id]/edit.vue")
  },
  {
    name: "reservations-id-view",
    path: "/reservations/:id()/view",
    meta: view7fhPxFCE_457XqZoXcWBQ7PZYMbvDT1iFTJb0YHQsgldkMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reservations/[id]/view.vue")
  },
  {
    name: "reports-agencies-report",
    path: "/reports/agencies-report",
    meta: agencies_45reportOCDFMyfCXKGbHm1YYmhCbpHoqUo_3Z1V7Yv1J9RfA9EMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reports/agencies-report.vue")
  },
  {
    name: "reports-week-pre-manifest",
    path: "/reports/week-pre-manifest",
    meta: week_45pre_45manifestTKNYPqnyZg_n1JNKrJj7ldytWCxFSE8sgyOTJfM8ZbQMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reports/week-pre-manifest.vue")
  },
  {
    name: "reports-total-reservations",
    path: "/reports/total-reservations",
    meta: total_45reservationsi42W8IbjDFo68fUjh1nImALgrJlfvtfh9qofIXxIOVUMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reports/total-reservations.vue")
  },
  {
    name: "settings-hotels-id-rates",
    path: "/settings/hotels/:id()/rates",
    meta: ratesZqWq1gbthNvdlu_cfIJ0PIKQH54q7hL6kQ2Vl76EKfwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/settings/hotels/[id]/rates.vue")
  },
  {
    name: "reports-arrivals-without-flight",
    path: "/reports/arrivals-without-flight",
    meta: arrivals_45without_45flightRagAUYvPBrR93ZasjoHY9x3Ka20sVhbuIb8zzSXGds0Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/reports/arrivals-without-flight.vue")
  },
  {
    name: "settings-roles-id-permissions",
    path: "/settings/roles/:id()/permissions",
    meta: permissionss3u_45Phs_45FkpqK9cDzF0abMf8_qR7vt2GIZbZc3knd6sMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/settings/roles/[id]/permissions.vue")
  }
]