import payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_6GPbmZsb4vIpERkdNE1vwJ9Qudnbce10AqJyw4MNGu4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8pzU2dGTfT7IMJBNLk_OAgE2ZXQEAofYB7657CZsZtg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_idjtGf87lIIwn9T3hRNe2ormzAfmk2ZrIZ1ez3_5PP0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6oG8bBQw5XrkBsKRtluI7WWeomBVP7Hw7NPR0ey8TAI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tvJuUcHvHTz6eRCeN0u9CiyL6hw5yVZT5n43JuD2Qjo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_V7SoEv5zL_812o3P4411dVUt0NoEXHs9HoaKexicjh0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KdPGm9JXsnbO10YkPSZoWEg1_K2iRqEWTfcKHTllCwg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_2_NT6bKDU87CuevKJGhhoilt87f7tW9R8ulPIU5EOX8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_2d42a24c0057645237c75595c58c6818/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_W6SJ0et5Gm_KeNTUq0Ohpl7yPKelONQtcNUkjWE46RY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.13_vue@3.5.13_typescript@5.8.2___magica_899d836a579d888ae7d08178f2950a2e/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_client_l_LcO2PHX_TdqwFKP4ftolOv_RgkWzi8SCs187JWRgo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-laravel-echo@0.2.2/node_modules/nuxt-laravel-echo/dist/runtime/plugin.client.js";
import plugin_Oi0PdZ7ZSxWGSgZRoz8AGBY9Vg_aHPh_q_9gqhK_lpg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-auth-sanctum@0.5.8/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FBJ689_mvNg48cDtBUp8QuhDMdclLBBfYiHRjLBEaYo from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.2.3_@types+node@22.13.10_jiti@2.4.2_lightni_730e8bca7618832a632b3187ef42eaf2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import slideovers_8NeSqj0dANGr_AG47UQ50LbEg8glkvpUUJS1lstPFAg from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.3_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2._721c96c5d69c7e612e21c853b999116b/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FhRi6_pYDjmjWR2rz8cYvDCQgK9XCk172FUdI3HAe9E from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.3_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2._721c96c5d69c7e612e21c853b999116b/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_2HzIRC_Q9VZm9Uqy0f0K12nCP2huo6786GWsUvg2mMA from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.1_axios@1.8.3_change-case@5.4.4_magicast@0.3.5_typescript@5.8.2_vite@6.2._721c96c5d69c7e612e21c853b999116b/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_C0H__LQ1LrxhL6sH_QVphnlj4k4JayV0MIhrl1Nm_ck from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.10_jiti@2.4.2_lightningcs_5b8d9ddca10d25d529aeaf178f386a8b/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import permissions_w5XSS0pKJGsgBrD5Cdzpa_DByUhBTvO_k_hlNnddpKQ from "/opt/buildhome/repo/app/plugins/permissions.ts";
import api_84elem0X4yBmBiZUVE7UuQp73h8h21064ANeRANSla4 from "/opt/buildhome/repo/app/plugins/api.ts";
import authorization_resolver_fga0p8APDwhW_Kgwsv2LwxKG03W_YWWkmw7JcFvBvds from "/opt/buildhome/repo/app/plugins/authorization-resolver.ts";
import presets_uh7fdnxCEy2Kt73B_KFsdVAJQn_HW05AhuDBaE2gVKk from "/opt/buildhome/repo/app/plugins/presets.ts";
import pusher_client_GbZ8e3O0cPDXvdzaqV_lioFpR_8K_B1iIi63defLCwg from "/opt/buildhome/repo/app/plugins/pusher.client.ts";
import pwa_update_4I4T7l7_BJmogsP2g4FwB5L_jO5_AIlT_PuNXczf9Oc from "/opt/buildhome/repo/app/plugins/pwa-update.ts";
import stores_eE_S_Qie_h5xZVVtyxDI1i3qRUVip4uvfEtFUkEhKTY from "/opt/buildhome/repo/app/plugins/stores.ts";
export default [
  payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4,
  revive_payload_client_6GPbmZsb4vIpERkdNE1vwJ9Qudnbce10AqJyw4MNGu4,
  unhead_8pzU2dGTfT7IMJBNLk_OAgE2ZXQEAofYB7657CZsZtg,
  router_idjtGf87lIIwn9T3hRNe2ormzAfmk2ZrIZ1ez3_5PP0,
  payload_client_6oG8bBQw5XrkBsKRtluI7WWeomBVP7Hw7NPR0ey8TAI,
  navigation_repaint_client_tvJuUcHvHTz6eRCeN0u9CiyL6hw5yVZT5n43JuD2Qjo,
  check_outdated_build_client_V7SoEv5zL_812o3P4411dVUt0NoEXHs9HoaKexicjh0,
  chunk_reload_client_KdPGm9JXsnbO10YkPSZoWEg1_K2iRqEWTfcKHTllCwg,
  plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_2_NT6bKDU87CuevKJGhhoilt87f7tW9R8ulPIU5EOX8,
  plugin_W6SJ0et5Gm_KeNTUq0Ohpl7yPKelONQtcNUkjWE46RY,
  plugin_client_l_LcO2PHX_TdqwFKP4ftolOv_RgkWzi8SCs187JWRgo,
  plugin_Oi0PdZ7ZSxWGSgZRoz8AGBY9Vg_aHPh_q_9gqhK_lpg,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  pwa_client_FBJ689_mvNg48cDtBUp8QuhDMdclLBBfYiHRjLBEaYo,
  slideovers_8NeSqj0dANGr_AG47UQ50LbEg8glkvpUUJS1lstPFAg,
  modals_FhRi6_pYDjmjWR2rz8cYvDCQgK9XCk172FUdI3HAe9E,
  colors_2HzIRC_Q9VZm9Uqy0f0K12nCP2huo6786GWsUvg2mMA,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_C0H__LQ1LrxhL6sH_QVphnlj4k4JayV0MIhrl1Nm_ck,
  permissions_w5XSS0pKJGsgBrD5Cdzpa_DByUhBTvO_k_hlNnddpKQ,
  api_84elem0X4yBmBiZUVE7UuQp73h8h21064ANeRANSla4,
  authorization_resolver_fga0p8APDwhW_Kgwsv2LwxKG03W_YWWkmw7JcFvBvds,
  presets_uh7fdnxCEy2Kt73B_KFsdVAJQn_HW05AhuDBaE2gVKk,
  pusher_client_GbZ8e3O0cPDXvdzaqV_lioFpR_8K_B1iIi63defLCwg,
  pwa_update_4I4T7l7_BJmogsP2g4FwB5L_jO5_AIlT_PuNXczf9Oc,
  stores_eE_S_Qie_h5xZVVtyxDI1i3qRUVip4uvfEtFUkEhKTY
]